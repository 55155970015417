import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { graphql, useStaticQuery } from "gatsby"

import "./styles/results.scss"

import Img from "gatsby-image"

const SecondPage = () => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  let urlParams = ""
  if (typeof window !== "undefined") {
    urlParams = new URLSearchParams(window.location.search)
  }
  const urlParamIsobj = typeof urlParams === "object"

  const where = urlParamIsobj ? urlParams.get("where") : null
  const what = urlParamIsobj ? urlParams.get("what") : null
  const date = urlParamIsobj ? urlParams.get("date") : null
  const dateObj = new Date(date)

  if (
    typeof window !== "undefined" &&
    (!where || where === "Select" || !what || what === "Select" || !date)
  ) {
    window.location = "/"
  }

  const month = months[dateObj.getMonth()]
  const day = dateObj.getDate()
  const year = dateObj.getFullYear()

  const imgList = useStaticQuery(graphql`
    query {
      img1: file(
        relativePath: { eq: "places/tim-foster-yKM-NVCYSBI-unsplash.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img2: file(
        relativePath: { eq: "places/tyler-nix-bbw55-xjeJo-unsplash.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img3: file(
        relativePath: { eq: "places/kyle-glenn-xY4r7y-Cllo-unsplash.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img4: file(
        relativePath: { eq: "places/alexa-west-dUPfhP18dPI-unsplash.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img5: file(
        relativePath: { eq: "places/airstream-inc-e4nZLI9Wbpg-unsplash.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img6: file(
        relativePath: { eq: "places/manuel-meurisse-flWsg878m_I-unsplash.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img7: file(
        relativePath: { eq: "places/nick-dunlap-3xpalrYpiwo-unsplash.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img8: file(
        relativePath: { eq: "places/dustin-belt-iYC4SXaANJc-unsplash.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img9: file(
        relativePath: { eq: "places/emmanuel-maceda-y2lXRKD-klc-unsplash.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img10: file(
        relativePath: { eq: "places/ethan-sykes-pgNyFfcNN9w-unsplash.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img11: file(
        relativePath: { eq: "places/nachelle-nocom-51adhgg5KkE-unsplash.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout isResults="true">
      <SEO title="Page two" />
      <header style={{}}>
        <div className="trvl">
          <p>TRVL</p>
        </div>
      </header>
      <h1>
        <span>Results for: </span>
        {`${what} in ${where} on ${month} ${day}, ${year}`}
      </h1>
      <div id="results-container">
        <div>
          <div>
            <Img className={"img"} fluid={imgList.img1.childImageSharp.fluid} />
          </div>
          <h2>Michigan Lakeside Teepee</h2>
          <p>Fishing rods, a fire pit, a boat and a floating dock.</p>
        </div>

        <div>
          <div>
            <Img className={"img"} fluid={imgList.img2.childImageSharp.fluid} />
          </div>
          <h2>Remote Arizona</h2>
          <p>
            Fall asleep to the sounds of the desert, wake up with the sunrise.
          </p>
        </div>

        <div>
          <Img className={"img"} fluid={imgList.img3.childImageSharp.fluid} />
          <h2>Oregonian Ecosphere</h2>
          <p>
            Regulates its own indoor temperature, generates its own electricity.
            Imagine that.
          </p>
        </div>

        <div>
          <div>
            <Img className={"img"} fluid={imgList.img4.childImageSharp.fluid} />
          </div>
          <h2>Hawaiian Treehouse</h2>
          <p>This view makes all the other views pale in comparison.</p>
        </div>

        <div>
          <div>
            <Img className={"img"} fluid={imgList.img5.childImageSharp.fluid} />
          </div>
          <h2>Airstream Island</h2>
          <p>
            Yes, you get the whole island to yourself. Yes, there is electricity
            and wifi.
          </p>
        </div>

        <div>
          <div>
            <Img className={"img"} fluid={imgList.img6.childImageSharp.fluid} />
          </div>
          <h2>California Vanning</h2>
          <p>
            Situated on a Malibu cliff, no people around for miles. Plus...a
            telescope!
          </p>
        </div>

        <div>
          <div>
            <Img className={"img"} fluid={imgList.img7.childImageSharp.fluid} />
          </div>
          <h2>Honolulu Pier</h2>
          <p>
            The van is viable transportation as well as its own final
            destination. Surfboard included.
          </p>
        </div>

        <div>
          <div>
            <Img className={"img"} fluid={imgList.img8.childImageSharp.fluid} />
          </div>
          <h2>Kona Magic</h2>
          <p>
            Converted trailer across the street from the best coffee you will
            ever drink, down the road from a local beach.
          </p>
        </div>

        <div>
          <div>
            <Img className={"img"} fluid={imgList.img9.childImageSharp.fluid} />
          </div>
          <h2>Adirondack Farm</h2>
          <p>
            The couple who lives in the main house is the grandparents you wish
            you had. Plus, ALPACAS!
          </p>
        </div>

        <div>
          <div>
            <Img
              className={"img"}
              fluid={imgList.img10.childImageSharp.fluid}
            />
          </div>
          <h2>Vermont Mountain Cabin</h2>
          <p>A woodburning stove is a thing to behold.</p>
        </div>

        <div>
          <div>
            <Img
              className={"img"}
              fluid={imgList.img11.childImageSharp.fluid}
            />
          </div>
          <h2>Tiny House, Hot Springs</h2>
          <p>
            The house is heated by an underwater hot water source that bubbles
            up into the adjacent huge, natural hot pool.
          </p>
        </div>
      </div>
      <Link to="/">Go back to the homepage</Link>
    </Layout>
  )
}

export default SecondPage
